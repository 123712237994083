export default function Landing() {
    return (
        <>
            <div className="landing">
                <p className="heading">
                    Cloud apps enhancing Jira and Confluence
                </p>

                <p className="heading">
                    built with top-notch security and privacy standards in mind.
                </p>

                <p className="heading-2">
                    Crafted by ex-Atlassian senior engineers.
                </p>

            </div>

            <div className="central-content">
                <div>
                    <p className="box-heading">
                        Extensive experience
                    </p>

                    <p className="box-content">
                        Prior to founding Acceleration, we have been working 5 years
                        in Atlassian, developing Jira and Forge extension points.
                        <br/>
                        <br/>
                        This time gave us a comprehensive and deep insight into Atlassian ecosystem.
                    </p>
                </div>
                <div>
                    <p className="box-heading">
                        Artificial intelligence
                    </p>

                    <p className="box-content">
                        We're building on&nbsp;
                        <a href="https://www.atlassian.com/software/rovo"
                           target='_blank'
                        >
                            Atlassian Rovo
                        </a>
                        , a new module designed
                        to use the power of AI for the benefit of the customers.
                    </p>
                </div>
                <div>
                    <p className="box-heading">
                        Security
                    </p>

                    <p className="box-content">
                        We understand that security is a top priority for enterprise customers.
                        <br/>
                        <br/>
                        We follow Atlassian requirements
                        and recommendations for the apps.
                    </p>
                </div>
                <div>
                    <p className="box-heading">
                        Data privacy
                    </p>

                    <p className="box-content">
                        We design and build our apps to process
                        only minimal amount of data required
                        to provide the functionality.
                        <br/><br/>
                        We’re transparent in how we treat customer data.
                    </p>
                </div>
                <div>
                    <p className="box-heading">
                        Support
                    </p>

                    <p className="box-content">
                        Our customers are our first priority.
                        <br/><br/>
                        We respond quickly to questions and support
                        requests.
                        <br/><br/>
                        In case of urgent matters, we work until the issue is resolved.
                    </p>
                </div>
                <div>
                    <p className="box-heading">
                        Reliability
                    </p>

                    <p className="box-content">
                        Serious business needs reliable tools.
                        Reliability is our specialization.
                        <br/><br/>
                        We put an extra time on this area to make sure
                        our apps are available and working.
                    </p>
                </div>
            </div>

            <div className='were-starting'>
                <p className='starting-header'>We're starting 🛫</p>
                <p className='starting-paragraph'>
                    Stay up to date with the new apps and company updates by subscribing to our newsletter.
                </p>
                <p className='starting-paragraph'>Rare, but valuable e-mails only.</p>

                <p className='join-newsletter'>
                    <a href="https://acceleration-engineering.us9.list-manage.com/subscribe?u=51743caf14fa2f2c2ab7fcc0c&id=9c7f70569c"
                       target='_blank'
                    >
                        Join our newsletter here.
                    </a>
                </p>
            </div>
        </>
    )
}